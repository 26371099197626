import { template } from './template/template';

export const environment = {
  production: true,
  debug: false,
  useGo: true,
  lite: true,
  fast: true,
  isMobile: false,
  isLocal: false,
  template: template,
  apiUrl: 'https://api.greenware.valueminer.eu',
  messagingUrl: 'https://greenware.valueminer.eu/messaging',
  messagingSocketNamespace: 'https://greenware.valueminer.eu',
  messagingSocketPath: '/socket.io',
  exportUrl: 'https://greenware.valueminer.eu:8100',
  oauthUrl: 'https://oauth.greenware.valueminer.eu',
  imageUrl: 'https://oauth.greenware.valueminer.eu/profile/?id=',
  fileupload: 'https://oauth.greenware.valueminer.eu',
  go: 'https://greenware.valueminer.eu:1337',
  goPrefix: {},
  goSuffix: {},
  app: '',
  s3: 'valueminer.local',
  aws: {
    access_key_id: 'AKIAVQ3RHJYALBWTHAN4',
    access_key_secret: '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',
    s3_bucket: 'valueminer.local',
    s3_region: 'eu-central-1',
  },
  auth: {
    authurl: 'https://oauth.greenware.valueminer.eu/oauth/authorize/',
    tokenurl: 'https://oauth.greenware.valueminer.eu/oauth/token/',
    logouturl: 'https://oauth.greenware.valueminer.eu/wp-login.php?action=logout',
    authorization: {},
    clientID: 'valueminer',
    mobileClientId: 'mobile',
    redirectURL: 'https://greenware.valueminer.eu/',
    scopes: ['api', 'users']
  },
  socket: {
    url: 'https://greenware.valueminer.eu:1337',
    path: '/socket.io/'
  },
  design: {
    key: 'greenware',
    logo: './assets/png/greenware-logo.png',
    title: './assets/png/greenware.png',
    titleIsImage: true,
    subtitle: 'powered by ValueMiner',
    welcomeText: 'Welcome to <span>Greenware Flexx</span>'
  },
  roleSelection: {
    norole: undefined
  },
  mobileDesign: {}
};
